<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-form label-position="left">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="购买页富文本说明">
              <br>
              <y_editor v-model="form.buy_page_desc" eid="buy-page-desc"></y_editor>
            </el-form-item>
            <el-form-item label="购买协议">
              <br>
              <y_editor v-model="form.buy_agreement" eid="buy-agreement"></y_editor>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批发商激活金额" class="flex-def">
              <el-input-number v-model="form.fee"></el-input-number>
            </el-form-item>
            <el-form-item label="赠送激活卡数量" class="flex-def">
              <el-input-number v-model="form.num"></el-input-number>
            </el-form-item>
            <el-form-item label="购买页划线价格" class="flex-def">
              <el-input-number v-model="form.buy_page_o_fee"></el-input-number>
            </el-form-item>
            <el-form-item label="购买页项目标题" class="flex-def">
              <el-input v-model="form.buy_page_title"></el-input>
            </el-form-item>
            <el-form-item label="购买页项目logo">
              <y_upload_img v-model="form.buy_page_logo"></y_upload_img>
            </el-form-item>
            <el-form-item label="升级代理文案">
              <el-input v-model="form.up_agent_copy_writing"></el-input>
            </el-form-item>
            <el-form-item label="升级老板文案">
              <el-input v-model="form.up_boss_copy_writing"></el-input>
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import y_editor from "@/components/y-form/y-editor/y_editor";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
export default {
  name: "edit",
  components: {
    y_editor,
    y_upload_img,
  },
  data() {
    return {
      form: {
        fee:0,
        num:0,
        buy_agreement:"",
        buy_page_o_fee:0,
        buy_page_title:"",
        buy_page_logo:"",
        buy_page_desc:"",

        up_agent_copy_writing:"",
        up_boss_copy_writing:"",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.card.plugin.wholesaler.conf().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.card.plugin.wholesaler.confEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>